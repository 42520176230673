exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1auJ3{background:url(\"/images/why-choose-us-bg.png\");height:290px;background-size:cover}._1VcvK{width:957px}._287M3{height:73px}._2b5aQ{font-size:18px;margin-right:120px}._2WpT5{width:218px;height:58px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}", ""]);

// exports
exports.locals = {
	"Root": "_1auJ3",
	"Content": "_1VcvK",
	"InfoSection": "_287M3",
	"Text": "_2b5aQ",
	"Button": "_2WpT5"
};