import React from 'react';
import classNames from 'classnames';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { getI18N } from 'shared/hoc';
import Image from 'frontend/components/static-image';
import { bool } from 'prop-types';
import Link from 'shared/components/link';
import s from './join-now.scss';

export default compose(
  withStyles(s),
  getI18N,
  setPropTypes({
    isLogin: bool.isRequired,
  }),
  setDisplayName(__filename),
)(({ i18n, isLogin }) => (
  <div className={classNames('container', s.Root)}>
    <Image className={s.Image} src="/images/mine.png" />
    <div className={classNames('font-weight-bold', s.Title)}>
      {i18n('joinNowTitle1')}
      {i18n('joinNowTitle2')}
    </div>
    <Link
      className={classNames('btn', 'btn-primary', s.Button)}
      to={isLogin ? '/contract/contract' : '/auth/register'}
    >
      {i18n('joinNowButton')}
    </Link>
  </div>
));
