import React from 'react';
import Layout from 'frontend/components/layout/layout';
import { compose, setDisplayName } from 'recompose';
import { connect } from 'react-redux';

import selector from '../home-selector';
import Branding from '../../components/web/branding';
import ProsAndCons from '../../components/web/pros-and-cons';
import WhyChooseUs from '../../components/web/why-choose-us';
import Advantage from '../../components/web/advantage';
import Mine from '../../components/web/mine';
import JoinNow from '../../components/web/join-now';

export default compose(
  connect(selector),
  setDisplayName(__filename),
)(({
  isLogin,
  advantageImages,
  disadvantageImages,
}) => (
  <Layout style={{ backgroundColor: 'white' }}>
    <Branding isLogin={isLogin} />
    <ProsAndCons />
    <WhyChooseUs />
    <Advantage
      advantageImages={advantageImages}
      disadvantageImages={disadvantageImages}
    />
    <Mine />
    <JoinNow isLogin={isLogin} />
  </Layout>
));
