exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._27yB7{margin-top:60px;width:957px}.PMMW0{color:#fff;width:360px;background-color:#fbb03b}._3zmuR,.PMMW0{height:300px;border:1px solid #fbb03b;padding:29px 0;text-align:center}._3zmuR{color:#000;width:268px}._2QkFI{height:140px}._1pikN{font-size:18px;margin-top:12px;height:50px;line-height:25px}._4hxuU{width:120px;height:36px;font-size:12px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;margin:0 auto}._3vbhF{color:#fbb03b;background-color:#fff}._3vbhF:hover{border:1px solid #fff}", ""]);

// exports
exports.locals = {
	"Root": "_27yB7",
	"MineDescription__Dark": "PMMW0",
	"MineDescription": "_3zmuR",
	"Image": "_2QkFI",
	"Title": "_1pikN",
	"Button": "_4hxuU",
	"ButtonWhite": "_3vbhF"
};