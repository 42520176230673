import React from 'react';
import { array } from 'prop-types';
import classNames from 'classnames';
import {
  compose,
  setDisplayName,
  setPropTypes,
  withStateHandlers,
} from 'recompose';
import { map } from 'lodash/fp';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { getI18N } from 'shared/hoc';
import Slider from 'react-slick';
import slick from 'slick-carousel/slick/slick.css';
import slickTheme from 'slick-carousel/slick/slick-theme.css';
import Image from 'frontend/components/static-image';
import s from './advantage.scss';

const ADVANTAGE = 'ADVANTAGE';
const DISADVANTAGE = 'DISADVANTAGE';

export default compose(
  setPropTypes({
    advantageImages: array,
    disadvantageImages: array,
  }),
  withStyles(s, slick, slickTheme),
  getI18N,
  withStateHandlers(() => ({
    selection: ADVANTAGE,
  }), {
    setSelection: () => value => ({
      selection: value,
    }),
  }),
  setDisplayName(__filename),
)(({
  i18n,
  selection,
  setSelection,
  advantageImages,
  disadvantageImages,
}) => (
  <div className={classNames('container', s.Root)} id="advantage">
    <p className={classNames('h1', 'font-weight-bold', s.Title)}>
      {i18n(selection === ADVANTAGE ? 'advantage' : 'disadvantage')}
    </p>
    <div className={classNames('d-flex', 'justify-content-start', s.Content)}>
      <div className={classNames('d-flex', 'flex-column', 'justify-content-between', s.Selection)}>
        <div
          className={classNames(
            s.SelectionItem,
            selection === ADVANTAGE && s.SelectedItem,
          )}
          onClick={() => setSelection(ADVANTAGE)}
          role="presentation"
        >
          {i18n('advantageSelection1')}
        </div>
        <div
          className={classNames(
            s.SelectionItem,
            selection === DISADVANTAGE && s.SelectedItem,
          )}
          onClick={() => setSelection(DISADVANTAGE)}
          role="presentation"
        >
          {i18n('advantageSelection2')}
        </div>
      </div>
      <div style={{ width: 657 }}>
        <Slider {...{
          dots: true,
          infinite: true,
          autoplay: false,
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
        }}
        >
          { map(({ key, url }) => (
            <div key={key}>
              <div
                className={classNames('d-flex', 'flex-column', 'justify-content-center', 'align-items-center', s.ImageContainer)}
              >
                <Image className={s.Image} src={url} />
                <div className={s.Text}>{i18n(key)}</div>
              </div>
            </div>
          ))(selection === ADVANTAGE ? advantageImages : disadvantageImages) }
        </Slider>
      </div>
    </div>
  </div>
));
