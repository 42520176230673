exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2-v1s,._3ExlP:hover{cursor:pointer;border-bottom:2px solid #fbb03b}._2-v1s:before,._3ExlP:hover:before{background-color:#fbb03b}._33D6Q{margin-top:118px;width:957px}._2nlCv{font-size:30px}._3JjNs{margin-top:60px;height:316px}._3JjNs .slick-list{margin-right:-14px!important}._3JjNs .slick-dots{cursor:pointer;margin-bottom:-20px}._3JjNs .slick-dots li button:before{content:\"\";color:#fff;opacity:1;font-size:10px;width:10px;height:10px;border:2px solid #b3b3b3;border-radius:10px}._3JjNs .slick-dots li.slick-active button:before{background-color:#b3b3b3}._1bDFt{padding:65px 0 100px;-ms-flex-positive:1;flex-grow:1}._3ExlP{position:relative;margin-left:40px;padding-bottom:5px;min-width:215px;font-size:12px}._3ExlP:before{content:\" \";border:1px solid #fbb03b;padding:14px;border-radius:30px;position:absolute;left:-40px;top:-5px}.PPLZl{width:210px;height:267px;border:1px solid #fbb03b;margin-right:14px;color:#fbb03b;text-align:center}.PPLZl:last-child{margin-right:0}._1ljiW{max-width:100px;width:100%}._2cDpt{margin-top:70px;font-size:12px;padding:0 20px}", ""]);

// exports
exports.locals = {
	"SelectionItem": "_3ExlP",
	"SelectedItem": "_2-v1s",
	"Root": "_33D6Q",
	"Title": "_2nlCv",
	"Content": "_3JjNs",
	"Selection": "_1bDFt",
	"ImageContainer": "PPLZl",
	"Image": "_1ljiW",
	"Text": "_2cDpt"
};