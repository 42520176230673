import React from 'react';
import classNames from 'classnames';
import { compose, setDisplayName } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { getI18N } from 'shared/hoc';
import Image from 'frontend/components/static-image';
import Link from 'shared/components/link';
import jump from 'jump.js';
import s from './mine.scss';

export default compose(
  withStyles(s),
  getI18N,
  setDisplayName(__filename),
)(({ i18n }) => (
  <div className={classNames('container', 'd-flex', 'justify-content-between', s.Root)}>
    <div className={s.MineDescription__Dark}>
      <Image className={s.Image} src="/images/mine-description-1.png" />
      <div className={classNames('font-weight-bold', s.Title)}>
        <div>{i18n('mineDescTitle1')}</div>
      </div>
      <Link
        className={classNames('btn', 'btn-outline-primary', s.Button, s.ButtonWhite)}
        to="/article/why"
      >
        {i18n('mineDescButton')}
      </Link>
    </div>
    <div className={s.MineDescription}>
      <Image className={s.Image} src="/images/mine-description-2.png" />
      <div className={classNames('font-weight-bold', s.Title)}>
        <div>{i18n('mineDescTitle2')}</div>
      </div>
      <button
        type="button"
        className={classNames('btn', 'btn-outline-primary', s.Button)}
        onClick={() => jump('#advantage', 1000)}
      >
        {i18n('mineDescButton')}
      </button>
    </div>
    <div className={s.MineDescription}>
      <Image className={s.Image} src="/images/mine-description-3.png" />
      <div className={classNames('font-weight-bold', s.Title)}>
        <div>{i18n('mineDescTitle3')}</div>
      </div>
      <Link
        className={classNames('btn', 'btn-outline-primary', s.Button)}
        to="/article/contract"
      >
        {i18n('mineDescButton')}
      </Link>
    </div>
  </div>
));
