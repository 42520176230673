import { getEntity } from 'relient/selectors';

export default state => ({
  isLogin: getEntity('auth.isLogin')(state),
  advantageImages: [
    { key: 'advantage1', url: '/images/advantage-1.png' },
    { key: 'advantage2', url: '/images/advantage-2.png' },
    { key: 'advantage3', url: '/images/advantage-3.png' },
    { key: 'advantage4', url: '/images/advantage-4.png' },
    { key: 'advantage5', url: '/images/advantage-5.png' },
    { key: 'advantage6', url: '/images/advantage-6.png' },
  ],
  disadvantageImages: [
    { key: 'disadvantage1', url: '/images/disadvantage-1.png' },
    { key: 'disadvantage2', url: '/images/disadvantage-2.png' },
    { key: 'disadvantage3', url: '/images/disadvantage-3.png' },
    { key: 'disadvantage4', url: '/images/disadvantage-4.png' },
    { key: 'disadvantage5', url: '/images/disadvantage-5.png' },
    { key: 'disadvantage6', url: '/images/disadvantage-6.png' },
    { key: 'disadvantage7', url: '/images/disadvantage-7.png' },
    { key: 'disadvantage8', url: '/images/disadvantage-8.png' },
    { key: 'disadvantage9', url: '/images/disadvantage-9.png' },
  ],
});
