exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BHWbR{margin-top:130px;text-align:center;width:957px}._1Cc_1{font-size:22px;margin-top:40px;line-height:66px;width:890px}._1YXah{margin-top:113px;-ms-flex-pack:justify;justify-content:space-between}._1u-gW,._1YXah{display:-ms-flexbox;display:flex}._1u-gW{height:65px;font-weight:700;color:#000;width:270px;font-size:18px;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}.ftMp9{margin-top:52px;font-size:18px}.ftMp9>a{color:#000;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}.kZ5kV{margin-left:24px}", ""]);

// exports
exports.locals = {
	"Root": "BHWbR",
	"Description": "_1Cc_1",
	"Buttons": "_1YXah",
	"Button": "_1u-gW",
	"InfoLink": "ftMp9",
	"InfoIcon": "kZ5kV"
};