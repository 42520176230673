import React from 'react';
import { HOME } from 'frontend/constants/features';

import Home from './containers/web/home';

export default () => [{
  path: '',
  feature: HOME,
  action: async () => ({
    component: <Home />,
  }),
}];
