import React from 'react';
import classNames from 'classnames';
import { compose, setDisplayName } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { getI18N } from 'shared/hoc';
import Link from 'shared/components/link';
import s from './why-choose-us.scss';

export default compose(
  withStyles(s),
  getI18N,
  setDisplayName(__filename),
)(({ i18n }) => (
  <div className={classNames('d-flex', 'align-items-center', s.Root)}>
    <div className={classNames('container', s.Content)}>
      <div className={classNames('d-flex', 'justify-content-start', 'mt-5', s.InfoSection)}>
        <div className={classNames('d-flex', 'flex-column', 'justify-content-between', s.Text)} />
        <div className="d-flex align-items-end">
          <Link
            className={classNames('btn', 'btn-primary', 'mb-2', s.Button)}
            to="/"
          >
            {i18n('knowMore')}
          </Link>
        </div>
      </div>
    </div>
  </div>
));
