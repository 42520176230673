exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2CU-e{margin:130px auto;width:957px}._1HZDu{margin:24px auto 0}._1HZDu>*{width:50%;text-align:center}._1FMLz{line-height:108px}.UJ0Pq{width:160px;height:160px}", ""]);

// exports
exports.locals = {
	"Root": "_2CU-e",
	"Group": "_1HZDu",
	"Item": "_1FMLz",
	"Icon": "UJ0Pq"
};