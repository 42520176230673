exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3vpcP{margin-top:130px;text-align:center}.pJrdK{width:351px;height:145px}._1n1zC{margin-top:66px;font-size:30px;color:#fbb03b}._3xkE-{margin-top:82px;width:410px;height:86px;font-size:24px;line-height:76px}", ""]);

// exports
exports.locals = {
	"Root": "_3vpcP",
	"Image": "pJrdK",
	"Title": "_1n1zC",
	"Button": "_3xkE-"
};