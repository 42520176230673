import React from 'react';
import { bool } from 'prop-types';
import classNames from 'classnames';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { getI18N } from 'shared/hoc';
import Link from 'shared/components/link';
import Image from 'frontend/components/static-image';
import jump from 'jump.js';
import s from './branding.scss';

export default compose(
  setPropTypes({
    isLogin: bool.isRequired,
  }),
  withStyles(s),
  getI18N,
  setDisplayName(__filename),
)(({ i18n, isLogin }) => (
  <div className={classNames('container', s.Root)}>
    <p className={s.Description}>
      {i18n('bannerSubTitle')}
    </p>
    <p className={classNames('h1', 'font-weight-bold')}>
      {i18n('brandingTitle')}
    </p>
    <div className={s.Buttons}>
      <button
        type="button"
        className={classNames('btn', 'btn-outline-primary', s.Button)}
        onClick={() => jump('#prosAndCons', 1000)}
      >
        {i18n('knowMore')}
      </button>
      <Link
        className={classNames('btn', 'btn-outline-primary', s.Button)}
        to={isLogin ? '/contract/contract' : '/auth/register'}
      >
        {i18n('startMining')}
      </Link>
      <Link
        className={classNames('btn', 'btn-outline-primary', s.Button)}
        to="/article/contract"
      >
        {i18n('contractDesc')}
      </Link>
    </div>
    <div className={s.InfoLink}>
      <Link to="/article/what">
        {i18n('whatIs')}
        <Image className={s.InfoIcon} src="/images/arrow-right.png" />
      </Link>
    </div>
  </div>
));
