import React from 'react';
import classNames from 'classnames';
import { compose, setDisplayName } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { getI18N } from 'shared/hoc';
import Image from 'frontend/components/static-image';
import { map } from 'lodash/fp';
import s from './pros-and-cons.scss';

const mapWithIndex = map.convert({ cap: false });

export default compose(
  withStyles(s),
  getI18N,
  setDisplayName(__filename),
)(({ i18n }) => (
  <div id="prosAndCons" className={classNames('container', s.Root)}>
    {
      mapWithIndex((_, index) => (
        <div
          key={index}
          className={classNames(
            'd-flex',
            'align-items-center',
            'justify-content-center',
            index % 2 === 0 ? 'flex-row-reverse' : '',
            s.Group,
          )}
        >
          <div>
            <Image className={s.Icon} src={`/images/pac${index + 1}.png`} />
          </div>
          <div className={s.Item}>
            <div>{i18n(`pocQuestion${index + 1}`)}</div>
            <div className="font-weight-bold">{i18n(`pocAnswer${index + 1}`)}</div>
          </div>
        </div>
      ))(Array.from({ length: 3 }))
    }
  </div>
));
